import React, { useState } from 'react';
import { Container, Row, Col, Button, ButtonToolbar } from 'react-bootstrap';

import SearchSelect from '../shared/asyncSelect/SearchSelect';
import api from '../../scripts/api';
import { message } from '../shared/Message';

function OrganizationIndexActions({addOrganizationClickHandler, selectResult}) {
	const [noSearchOptions] = useState('No matching organizations found.');
	const [pageSize] = useState(50);
	const [pageNum] = useState(0);
	let errorMessageId = null;

	const searchOrganizations = async (searchTerm, loadedOptions, { pageNum, pageSize }) => {
		let result;

		try {
			result = await api.searchAllOrganizations(searchTerm, pageNum, pageSize)
		} catch (err) {
			message.dismiss(errorMessageId);
			errorMessageId = message.error(`Error searching for organization: ${err.title}`)
			return { options: [] };
		}

		const searchResults = result.searchResults.map(dto => ({
			label: dto.name,
			value: dto.id,
		}))

		const hasMore = result.totalMatches > (pageNum * pageSize) + searchResults.length;

		return {
			options: searchResults,
			hasMore: hasMore,
			additional: {
				pageNum: pageNum + 1,
				pageSize: pageSize
			}
		}
	}

	return (
		<React.Fragment>
				<Container>
					<Row>
						<Col className="pl-0" md="6">
							<SearchSelect
								placeholder="Search organizations"
								noOptionsMessage={() => noSearchOptions}
								loadOptions={searchOrganizations}
								additional={{ pageNum: pageNum, pageSize: pageSize }}
								onChange={selectResult}
							/>
						</Col>
						<Col className="pr-0" md="6">
							<ButtonToolbar className="d-flex justify-content-end">
                <Button
									variant="primary"
									onClick={addOrganizationClickHandler}>
									Add Organization
								</Button>
							</ButtonToolbar>
						</Col>
					</Row>
				</Container>
		</React.Fragment>
	)
}

export default OrganizationIndexActions
