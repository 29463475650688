import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import ScheduledJobListDto from '../../../DTOs/ScheduledJob/ScheduledJobListDto.js';
import { getScheduledJobListAsync } from '../../../scripts/api/scheduledJobApi.js';
import { message } from '../../shared/Message.js';

const ScheduledJobsIndex = () => {
  const [scheduledJobList, setscheduledJobList] = useState(new ScheduledJobListDto());

  useEffect(() => {
    getScheduledJobListAsync().then(result => {
      setscheduledJobList(result);
    }).catch(err => {
      message.error(`Error retrieving scheduled job list: ${err.message}`);
    });
  }, [])

    return (
      <React.Fragment>
        <h1>Scheduled Jobs</h1>
        <DataTable
          value={scheduledJobList.scheduledJobs}
          tableStyle={{ minWidth: '50rem' }}
          stripedRows
          paginator rows={25}
          sortMode="multiple"
          sortField="runAfterUtc"
          sortOrder={-1}
          filterDisplay="row"
          selectionMode="single"
          dataKey="id"
        >
          <Column field="jobTypeName" header="Job Type" sortable filter showFilterMenu={true}></Column>
          <Column field="message" header="Message" filter></Column>
          <Column field="runAfterUtc" header="Run After"></Column>
          <Column field="startedUtc" header="Started"></Column>
          <Column field="succeededUtc" header="Succeeded"></Column>
          <Column field="failedUtc" header="Failed"></Column>
        </DataTable>
      </React.Fragment>
    );
  }

export default ScheduledJobsIndex;
