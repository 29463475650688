import ScheduledJobDto from "./ScheduledJobDto";

export default class ScheduledJobListDto {
  constructor(options = {}) {
    this.scheduledJobs = options.scheduledJobs?.map(dto => new ScheduledJobDto(dto)) || [];
    this.pageIndex = options.pageIndex || 0;
    this.pageSize = options.pageSize || 10;
    this.totalMatches = options.totalMatches || 0;
  }

  stringify() {
    return JSON.stringify(this);
  }
}
