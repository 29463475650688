import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button, ButtonToolbar } from 'react-bootstrap';

import { getMeasureInputField } from '../../shared/inputs/inputHelpers';
import OrganizationInvalidDataDto from '../../../DTOs/OrganizationInvalidData/OrganizationInvalidDataDto.js';
import api from '../../../scripts/api.js';
import { message } from '../../shared/Message.js';

const UpdateInvalidDataRecord = ({ history, params }) => {
  const [invalidData, setInvalidData] = useState(new OrganizationInvalidDataDto());
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    api.getInvalidOrganizationDataRecord(params.id).then(result => {
      setInvalidData(result);
      setNewValue(result.fieldValue);
    }).catch(err => {
      message.error(`Error retrieving invalid data summary: ${err.message}`);
    });
  }, [params.id])

  function handleChange(e) {
    setNewValue(e.target.value);
  }

  function handleUpdateClick() {
    let model = new OrganizationInvalidDataDto(invalidData);
    model.fieldValue = newValue;
    model.startedUtc = new Date().toUTCString();
    setInvalidData(model);

    message.success("Update started and will continue even if you navigate to a different page");

    api.updateInvalidOrganizationData(model).then(result => {
      message.success("Update complete");
    }).catch(err => {
      message.error(`Error updating data: ${err.message}`);
    });
  }

  function handleReturnClick() {
    history.push("/Organization/UpdateInvalidDataIndex/");
  }

  function getOrganizationInfo() {
    if (invalidData.organizationId) {
      return `${invalidData.organizationName} (${invalidData.organizationId})`;
    }
    return "N/A (Global Measure)";
  }

    return (
      <React.Fragment>
        <Container className="basic-card pb-3">
          <Row>
            <Col md={6}>
              <h3>
                Update Invalid Data
              </h3>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
             Organization
            </Col>
            <Col>
              {getOrganizationInfo()}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              Measure
            </Col>
            <Col>
              {invalidData.measureName} ({invalidData.measureId})
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              Field Name
            </Col>
            <Col>
              {invalidData.fieldName}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              Field Value
            </Col>
            <Col>
              {invalidData.fieldValue}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              Validation Message
            </Col>
            <Col>
              {invalidData.validationMessage}
            </Col>
          </Row>
          {!invalidData.startedUtc && !invalidData.errorMessage ?
            <>
              <Row className="mt-3">
                {invalidData.fieldDefinition ?
                  <>
                    <Col md={3}>
                      New Value
                    </Col>
                    <Col>
                      {getMeasureInputField(invalidData.fieldDefinition, {
                        value: newValue,
                        onChange: handleChange,
                      })}
                    </Col>
                  </> : 
                  <></>
                }
              </Row>
              <Row>
                <ButtonToolbar className="d-flex justify-content-end mt-3">
                  <Button variant="primary" onClick={() => handleUpdateClick()}>Update Data</Button>
                  <Button variant="outline-primary" onClick={() => handleReturnClick()}>Back</Button>
                </ButtonToolbar>
              </Row>
            </>
            :
            <>
              <Row>
                <Col>
                  {invalidData.errorMessage ?
                    <span>Error: {invalidData.errorMessage}</span> :
                    <span>This data is currently being updated.  Start time:  {invalidData.startedUtc}</span>
                   }
                </Col>              
              </Row>
              <Row>
                <ButtonToolbar className="d-flex justify-content-end mt-3">
                  <Button variant="outline-primary" onClick={() => handleReturnClick()}>Back</Button>
                </ButtonToolbar>
              </Row>
            </>}
        </Container>
      </React.Fragment>
    );
  }

export default UpdateInvalidDataRecord;
