export default class ScheduledJobDto {
  constructor(options = {}) {
    this.id = options.id || null;
    this.jobType = options.jobType || null;
    this.jobTypeName = options.jobTypeName || '';
    this.message = options.message || "";

    this.runAfterUtc = options.runAfterUtc || null;
    this.startedUtc = options.startedUtc || null;
    this.succeededUtc = options.succeededUtc || null;
    this.failedUtc = options.failedUtc || null;
  }

  stringify() {
    return JSON.stringify(this);
  }
}
