import React  from 'react';
import { Container, Button, ButtonGroup } from 'react-bootstrap';

const AdminToolsIndex = ({ history, ...props }) => {
  function handleUpdateInvalidDataClick() {
    history.push('/Admin/UpdateInvalidDemographicsData/UpdateInvalidDataIndex');
  }

  function handleScheduledJobClick() {
    history.push("/Admin/ScheduledJob/ScheduledJobsIndex");
  }
  return (
    <React.Fragment>
      <Container className="basic-card pb-3">
        <h1>Admin Tools</h1>
        <ButtonGroup vertical>
          <Button onClick={handleUpdateInvalidDataClick} variant="link" className="text-left">Update Invalid Demographics Data</Button>
          <Button onClick={handleScheduledJobClick} variant="link" className="text-left">View Scheduled Jobs</Button>
        </ButtonGroup>
      </Container>
    </React.Fragment>
  );
}

export default AdminToolsIndex;
