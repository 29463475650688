import { apiCall } from "../api";
import ScheduledJobListDto from "../../DTOs/ScheduledJob/ScheduledJobListDto";
import ScheduledJobDto from "../../DTOs/ScheduledJob/ScheduledJobDto";

export const getScheduledJobListAsync = async () => {
  const url = `scheduledJob/getList`;
  let response = await apiCall(url);
  return new ScheduledJobListDto(response.result);
}

export const getScheduledJobByIdAsync = async (id) => {
  const url = `scheduledJob/${id}`;
  let response = await apiCall(url);
  return new ScheduledJobDto(response.result);
}
